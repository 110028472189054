import React from "react";

import AnnounceItem from "./announce-item";

class Announcement extends React.Component {

    constructor (props) {
        super(props)
        this.state = {
            newsList: []
        }
    }


    componentDidMount() {

        let url = '/wp-json/wp/v2/news?order=desc&per_page=5&orderby=date';
        fetch(url).then(res => res.json()).then(data => {
            this.setState({
                newsList: data
            })
        })

    }

    render() {

        const newsList = this.state.newsList;
        const newsListAnnounce = newsList.map((news) => 
            <AnnounceItem key={news.id} date={news.date} text={news.title.rendered} link={news.link}></AnnounceItem>
        )

        return (
            <div id="announcement" className="w-full py-9 px-5 lg:px-12 lg:py-20 lg:max-w-[980px] lg:mx-auto">
                <div className="mx-auto lg:flex lg:items-center lg:justify-between">
                    <div id="announce-head" className="text-center lg:text-left mb-3"><h1 className="inline-block w-56 lg:w-auto border-b lg:border-none border-solid border-black text-lg lg:text-2xl font-yu-mincho font-black">お知らせ</h1></div>
                    <div id="annouce-body" className="lg:max-w-none mx-auto lg:mx-0 lg:flex lg:mr-auto lg:ml-16">
                        <div id="announce-content" className="block w-fit mb-3 lg:border-l border-solid border-black mx-auto lg:px-5 lg:py-2">
                            {newsListAnnounce}
                        </div>

                    </div>
                    <div id="announce-more" className="text-center">
                            <a href="/news" className="inline-block font-yu-mincho py-1 px-3 lg:py-2 lg:px-6 bg-yuyu-btn font-black text-sm lg:text-xl">お知らせ一覧</a>
                    </div>
                </div>
            </div>
        )
    }

}

export default Announcement;