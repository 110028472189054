// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../images/class-image2.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../../images/class-image2.avif", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("../../images/class-image2.webp", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("../../images/open-map.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "html #icg-img-box{background-repeat:no-repeat;background-position:left;background-size:auto 100%}html.fallback #icg-img-box{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}html.avif #icg-img-box{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ")}html.webp #icg-img-box{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ")}i.i-location{display:inline-block;position:relative;top:2px}.i-location:before{content:\" \";background:url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") no-repeat center;width:10px;height:15px;display:block;background-size:contain}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
