// import css
import '../styles/global.scss';
import React from 'react';
import { createRoot } from 'react-dom/client';
import TaichiHeader from './components/header';
import TaichiFooter from './components/footer';
const NavigationOverlay = React.lazy(() => import('./components/navigation-overlay'))
import Fade from './components/fade';
import MainVisual from './components/main-visual';
import Introduction from './components/introduction';
import ClassGuide from './components/class-guide';
import Announcement from './components/announcement';
import IndividualClassGuide from './components/individual-class-guide';
import Access from './components/access';
import CommonApp from './_commonApp';
import BlogList from './components/blog-list';
import FloatNavigation from './components/float-navigation';


class App extends CommonApp {

    constructor(props) {
        super(props);
        this.closeNav = this.closeNav.bind(this)
        this.openNav = this.openNav.bind(this)
        this.state = {
            openOverlay: false
        }
    }

    componentDidMount() {
        this.checkAvailableImageFormat();
    }

    render() {
        return (
            <div id='wrapper' className='font-default-sans pt-[6rem] lg:pt-0'>
                <TaichiHeader openNav={this.openNav} active="home"></TaichiHeader>
                <MainVisual></MainVisual>
                <Introduction></Introduction>
                <ClassGuide></ClassGuide>
                <Announcement></Announcement>
                <IndividualClassGuide></IndividualClassGuide>
                <Access></Access>
                <BlogList></BlogList>
                <TaichiFooter></TaichiFooter>
                <Fade openOverlay={this.state.openOverlay} exClass='fixed w-screen h-screen top-0 lg:hidden left-full z-50'>
                    <NavigationOverlay closeNav={this.closeNav}></NavigationOverlay>
                </Fade>
                <FloatNavigation></FloatNavigation>
            </div>
        );
    }   

};

const root = createRoot(document.getElementById('app'));
root.render(<App />)