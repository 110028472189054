import React from "react";

class Introduction extends React.Component {

    render() {
        return (
            <div className="pt-5 pb-6 px-5 lg:px-16 md:pb-12 max-w-5xl lg:pb-24 font-yu-mincho w-full text-center sm:max-w-lg lg:max-w-5xl mx-auto">
                <div id="intro-head" className="pb-2 lg:text-left"><h1 className="font-black text-lg lg:text-2xl">はじめに</h1></div>
                <div id="intro-body" className="text-sm lg:text-xl text-left px-0 sm:px-5 lg:px-1">
                    <p>
                        漢方医学と気功はどちらも中国の伝統文化です。<br />
                        宇宙、自然と人との関係を学び、自然に順応する為の養生法です。
                    </p>
                </div>
            </div>
        )
    }

}

export default Introduction;