import React from "react";
import AnnounceItem from "./announce-item";

class BlogList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            blogList: []
        }
    }

    componentDidMount() {

        const url = '/wp-json/wp/v2/posts?order=desc&per_page=5&orderby=date';
        fetch(url).then(res => res.json()).then(data => {
            this.setState({
                blogList: data
            })
        })

    }

    render() {

        const blogList = this.state.blogList;
        const blogListItems = blogList.map((blog) => 
            <AnnounceItem key={blog.id} date={blog.date} text={blog.title.rendered} link={blog.link}></AnnounceItem>
        )


        return (
            <div id="blog" className="w-full py-9 px-5 lg:px-12 lg:py-20 lg:max-w-[980px] lg:mx-auto">
                <div className="mx-auto lg:flex lg:items-center lg:justify-between">
                    <div id="announce-head" className="text-center lg:text-left mb-3"><h1 className="inline-block w-56 lg:w-auto border-b lg:border-none border-solid border-black text-lg lg:text-2xl font-yu-mincho font-black">ブログ</h1></div>
                    <div id="annouce-body" className="lg:max-w-none mx-auto lg:mx-0 lg:flex lg:mr-auto lg:ml-16">
                        <div id="announce-content" className="block w-fit mb-3 lg:border-l border-solid border-black mx-auto lg:px-5 lg:py-2">
                            {blogListItems}
                        </div>
                    </div>
                    <div id="announce-more" className="text-center">
                            <a href="/blogs" className="inline-block font-yu-mincho py-1 px-3 lg:py-2 lg:px-6 bg-yuyu-btn font-black text-sm lg:text-xl">ブログ一覧</a>
                    </div>
                </div>
            </div>
        )
    }

}

export default BlogList;