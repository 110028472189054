import React from "react";

class Access extends React.Component {
    render() {
        return (
            <div id="access" className="pt-4 pb-7 px-3 lg:px-[4.2rem] lg:py-9 relative lg:flex mx-auto mb-11 mt-8 lg:mt-0 lg:max-w-[1365px]">
                <div id="access-info" className="lg:w-1/2 lg:pt-12">
                    <div id="access-head" className="text-center lg:text-left mb-3">
                        <h1 className="inline-block w-56 lg:w-auto border-b lg:border-none border-solid border-black text-lg lg:text-2xl font-yu-mincho font-black">アクセス</h1>
                    </div>
                    <div id="acces-text" className="font-yu-mincho text-sm lg:text-base relative mb-3 max-w-[473px] mx-auto md:mb-8 ">
                        〒819-1331糸島志摩久家468-1<br />
                        電話番号　<a href="tel:080-3901-6726">080-3901-6726</a><br />
                        ●お車でお越しの方<br />
                        道場前に駐車場３台分のご用意があります。<br />
                        予約制となっておりますので、お電話にてお問い合わせください。
                        <div id="access-btn" className="relative top-0 right-0 text-right xs:absolute lg:relative lg:mt-8">
                            <a target="_blank" href="https://goo.gl/maps/H42bbHcZ1vH8wuBx7" className="inline-block bg-yuyu-map-btn px-2 py-1 rounded text-sm text-yuyu-map-txt font-yu-mincho">
                                <i className="i-location mr-1"></i>
                                地図を開く
                            </a>
                        </div>                        
                    </div>

                </div>
                <div id="access-map" className="lg:w-1/2 lg:p-5">
                    <iframe
                        className="h-44 w-full md:h-80" 
                        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d831.2507760323705!2d130.1477261104568!3d33.55329567619366!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3541c2b2faf41c81%3A0x176f55b4d11ce526!2z44CSODE5LTEzMzEg56aP5bKh55yM57O45bO25biC5b-X5pGp5LmF5a6277yU77yW77yY4oiS77yR!5e0!3m2!1sja!2sjp!4v1651564243569!5m2!1sja!2sjp"
                        style={{ border: 0 }}
                        allowFullScreen="" 
                        loading="lazy" 
                        referrerPolicy="no-referrer-when-downgrade"></iframe>
                </div>
            </div>
        )
    }
}

export default Access