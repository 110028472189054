import React from "react";

import ClassGuideContent from "./class-guide-content";

import classImage from '../../images/class-image.png';
import classImage2x from '../../images/class-image_2x.png';
import classImage3x from '../../images/class-image_3x.png';
import classImageAvif from '../../images/class-image.avif';
import classImage2xAvif from '../../images/class-image_2x.avif';
import classImage3xAvif from '../../images/class-image_3x.avif';
import classImageWebp from '../../images/class-image.webp';
import classImage2xWebp from '../../images/class-image_2x.webp';
import classImage3xWebp from '../../images/class-image_3x.webp';

class ClassGuide extends React.Component {
    
    componentDidMount () {

        window.addEventListener('resize', function() {
            let bgWrap = document.getElementById('bg-wrap');
            if (this.document.body.clientWidth >= 1360)  {
                let test = document.getElementById('class-guide-image');

                bgWrap.style.width = ( test.getBoundingClientRect().left + test.offsetWidth + 50) + 'px';
            } else {
                bgWrap.style.width =  '100%';
            }
        })
        window.dispatchEvent(new Event('resize'));

    }

    render() {
        return (
            <div id="class-guide" className="relative font-yu-mincho ">
                    <div id="bg-wrap" className="bg-yuyu-ju  absolute top-0 left-0 w-full h-full"></div>
                    <div id="class-guide-main" className="lg:flex max-w-3xl lg:max-w-[60rem] mx-auto relative p-3 md:px-10 lg:py-10">
                        <div id="class-guide-head"><h1 className="text-base lg:text-2xl font-black text-center pb-1 lg:vertical-text lg:items-center lg:py-12">養生教室案内</h1></div>
                        <div id="class-guide-content" className="">
                            <ClassGuideContent header="漢方薬" 
                                text="気と血、経絡、病因を学びます。<br />
                                    未病健康管理の効果を達成し、老子、荘子の陰陽哲学と道家理論を学び、心と体の精神性を養います。"></ClassGuideContent>
                            <ClassGuideContent header="気功" text="八段錦、六字訣、五禽戲、中国伝統気功養生法は内臓と体気の陰陽バランスを改善します。<br />
                                    気功内容は豊富で、動作は簡単。<br />
                                    慢性病がある方もストレスを抱えやすい方も現代社会を、中国古来の知恵で明るく過ごしましょう。<br />"></ClassGuideContent>
                        </div>
                        <div id="class-guide-image" className="absolute hidden lg:block top-[-5rem] xl:top-[-6rem] w-72 xl:w-[21rem] right-12 xl:right-[-2rem]">
                            <picture>
                                <source srcSet={`${classImageAvif}, ${classImage2xAvif} 2x, ${classImage3xAvif} 3x`} type="image/avif" />
                                <source srcSet={`${classImageWebp}, ${classImage2xWebp} 2x, ${classImage3xWebp} 3x`} type="image/webp" />
                                <source srcSet={`${classImage},${classImage2x} 2x, ${classImage3x} 3x`} type="image/png" />
                                <img loading="lazy" src={ classImage } alt="太極-悠悠 World Chenpanling Family"  />
                            </picture>
                        </div>       
                    </div> 
            </div>
        )
    }

}

export default ClassGuide;