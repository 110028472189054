import React from "react";

import '../../styles/components/main-visual.scss';

class MainVisual extends React.Component {

    render() {
        return (
            <div id="main-visual" className="block lg:flex relative w-full h-44 md:h-96 lg:h-80 xl:h-96 2xl:h-[28rem] overflow-hidden">
                <div id="main-img-wrap" className="w-full h-44 md:h-96 lg:w-1/2 lg:h-full absolute lg:relative top-0 left-0"></div>
                <div id="main-text-wrap" 
                      className="f-full lg:w-1/2 absolute lg:relative top-0 left-0 h-full w-full  flex justify-center items-center bg-transparent lg:bg-yuyu-ju2">
                    <div className="text-left text-sm md:text-xl lg:text-2xl xl:text-[32px] font-black font-yu-gothic ">
                        四季を見つめながら<br />
                        何気ない日常を心地よく<br />
                        しぜんとつながる。
                    </div>
                </div>
            </div>
        )
    }
}

export default MainVisual;