import React from "react";

class AnnounceItem extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            title: '',
            date: new Date(props.date)
        }
    }

    componentDidMount() {
        window.addEventListener('resize', this.onResize.bind(this))
        window.dispatchEvent(new Event('resize'));
    }

    getDate() {
        return this.state.date.getFullYear() + '.' + (this.state.date.getMonth() + 1) + '.' + this.state.date.getDate()
    }

    render() {
        return(
        <div className="announce-item">
            <a href={this.props.link} className="mb-2 text-left">
            <span className="inline-block date mr-4 text-sm lg:text-base">{ this.getDate() }</span>
            <span className="inline-block title whitespace-nowrap text-sm lg:text-base">{ this.state.title }</span>
            </a>
        </div>

        )
    }

    onResize() {
        const bodyWidth = document.body.clientWidth

        let subStr = this.props.text
        
        if (bodyWidth < 414) {
            subStr = this.props.text.substring(0, 12);
        } else if(bodyWidth < 768) {
            subStr = this.props.text.substring(0, 16);
        } else {
            subStr = this.props.text.substring(0, 20); 
        }
        this.setState({
            title: subStr
        });
    }

}

export default AnnounceItem;