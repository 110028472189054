import React from "react";
import '../../styles/components/individual-class-guide.scss';
import PageSubHeader from "./page-sub-header";
import NormalClassHeader from "./normal-class-header";
import NormalClassPriceHeader from "./normal-class-price-header";
import OtherClassHeader from "./other-class-header";
import ClassNotification from "./class-notification";
import TryFree from "./try-free";
import ToContact from "./to-contact";

const IndividualClassInfo = React.lazy(() => import("./individual-class-info"));
const IndividualClass = React.lazy(() => import("./individual-class"));
const IndividualPrice = React.lazy(() => import("./individual-price"));

class IndividualClassGuide extends React.Component {

    componentDidMount () {
        window.addEventListener('resize', this.onWindowResize.bind(this))
        window.dispatchEvent(new Event('resize'));
    }

    onWindowResize(e) {
        let contentsWrap = document.getElementById('icg-wrap');
        let targetImgBox = document.getElementById('icg-img-box');
        let test = document.body.clientWidth - contentsWrap.offsetLeft 
        targetImgBox.style.right = (test + 70) + 'px'
    }

    render() {
        return(
            <div id="individual-class-guide" className="bg-yuyu-glay pt-4 pb-7 px-3 lg:px-[4.2rem] lg:py-9 relative">
                <div id="icg-img-box" className="absolute top-0 w-[1081px] h-full hidden 2xl:block"></div>
                <div id="icg-wrap" className="max-w-[1250px] 2xl:mx-auto">
                    <PageSubHeader id="guide-header" text="各教室のご案内"></PageSubHeader>
                    <div className="mb-7 lg:pl-4">
                        <p className="font-yu-mincho text-base">通常教室は会員全員が一番行きやすい各教室にて月３回太極拳を学んでいます。<br />
                        無料体験会を実施中。興味のある方は是非ご連絡下さい。</p>
                    </div>
                    <div id="normal-class-guide" className="overflow-hidden">
                        <NormalClassHeader></NormalClassHeader>
                        <div>
                            <div className="mx-auto lg:px-4 grid gap-4 grid-cols-1 md:grid-cols-2 lg:gap-8 xl:grid-cols-3">
                                <IndividualClass name="愛教室" kana="アイ" teacher="陳" color="bg-yuyu-th6">
                                    <IndividualClassInfo  week="第１、２、３月曜日" time="10:00～11:30" location="愛宕公民館" map="https://goo.gl/maps/C7WvNdCfxQt1yzAC8"></IndividualClassInfo>
                                </IndividualClass>
                                <IndividualClass name="熊猫教室" kana="パンダ" teacher="陳" color="bg-yuyu-th5">
                                    <IndividualClassInfo  week="第１、２、３火曜日" time="10:00～11:30" location="福重公民館" map="https://goo.gl/maps/Q3brj5wUB7kP2L8i6" ></IndividualClassInfo>
                                </IndividualClass>
                                <IndividualClass name="悠悠教室" kana="ユウユウ" teacher="陳" color="bg-yuyu-th5">
                                    <IndividualClassInfo  week="第１、２、３火曜日" time="14:00～15:30" location="糸島市健康福祉センター" map="https://goo.gl/maps/xocitZZUoFYjnAwz9" ></IndividualClassInfo>
                                </IndividualClass>
                                <IndividualClass name="水教室" kana="スイ" teacher="洞" color="bg-yuyu-th5">
                                    <IndividualClassInfo week="第１、２、３火曜日" time="19:00～20:30" location="桜井神社" map="https://goo.gl/maps/e8XFN12aShe2rMZ37"></IndividualClassInfo>
                                </IndividualClass>
                                <IndividualClass name="向日葵教室" kana="ヒマワリ" teacher="陳" color="bg-yuyu-th4">
                                    <IndividualClassInfo  week="第１、２、３木曜日" time="13:15～14:45" location="愛宕公民館" map="https://goo.gl/maps/C7WvNdCfxQt1yzAC8"></IndividualClassInfo>
                                </IndividualClass>
                                <IndividualClass name="你好教室" kana="ニイハオ" teacher="陳" color="bg-yuyu-th3">
                                    <IndividualClassInfo  week="第１、２、３土曜日" time="09:30～11:00" location="桜野コミュティーセンター" map="https://goo.gl/maps/UMDA4ej5bBnovCq7A"></IndividualClassInfo>
                                </IndividualClass>
                                <IndividualClass name="華教室" kana="ハナ" teacher="陳" color="bg-yuyu-th3">
                                    <IndividualClassInfo  week="第１、２、３土曜日" time="19:00～20:30" location="愛宕浜公民館" map="https://goo.gl/maps/K1ovjDVyAu9bMZgEA"></IndividualClassInfo>
                                </IndividualClass>
                                <IndividualClass name="若草教室" kana="ワカクサ" teacher="福島" color="bg-yuyu-th1">
                                    <IndividualClassInfo week="第１、２、３日曜日" time="08:30～10:00" location="石丸東公園" map="https://goo.gl/maps/rngpMvd4zWEjDTvX7"></IndividualClassInfo>
                                </IndividualClass>
                            </div>
                            <div className="mt-5">
                            <ClassNotification></ClassNotification>
                            </div>
                        </div>
                        
                        <NormalClassPriceHeader></NormalClassPriceHeader>
                        <div id="normal-price-body" className="lg:max-w-[51rem]">
                            <IndividualPrice></IndividualPrice>
                        </div>
                    </div>
                    <div id="other-classes" className="mt-11">
                        <OtherClassHeader></OtherClassHeader>
                        <div>
                            <p className="font-yu-mincho text-sm flex justify-center lg:block">
                                特別教室は通常教室の会員が新たに年間契約にて選んだコースを年間１０回受講できます。<br />
                                その他の教室は専門的な太極拳であったり氣功や漢方養生であったりを会員が希望に応じて受講できます。
                            </p>
                        </div>
                    </div>
                    <div id="other-classes-more" className="text-center lg:text-right mt-6">
                        <a href="/class" className="bg-yuyu-btn inline-block text-center text-sm font-black font-yu-mincho px-3 py-1 lg:text-xl  lg:w-[16rem]">詳しい教室内容はこちら</a>
                    </div>
                    <TryFree></TryFree>
                    <ToContact></ToContact>
                </div>
            </div>
        )
    }

}

export default IndividualClassGuide;